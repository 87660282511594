import http from '@/utils/http';

/**
 * 支付日志
 */
export default {
  getList: {
    p: 'post,/manage/payment/getlist',
    r: (data) => http({ url: '/manage/payment/getlist', method: 'post', data })
  },
  update: {
    p: 'post,/manage/payment/update',
    r: (data) => http({ url: '/manage/payment/update', method: 'post', data })
  },
  del: {
    p: 'post,/manage/payment/delete',
    r: (data) => http({ url: '/manage/payment/delete', method: 'post', data })
  },
  getOne: {
    p: 'post,/manage/payment/getone',
    r: (data) => http({ url: '/manage/payment/getone', method: 'post', data })
  },
  exportFile: {
    p: 'post,/manage/payment/export',
    r: (data) => http({ url: '/manage/payment/export', method: 'post', data })
  }
};
