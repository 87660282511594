import './list.scss';
import dataApi from '@/api/manage/platformaction.js';
/*
* 队列日志
* */
export default {
  name: 'member-points',
  components: {},
  data() {
    return {
      drawerInfoVisible: false,
      info: {},
      page: {
        pageSize: 20,
        pageSizes: [20, 30, 40, 50, 100]
      },
      tableData: [],
      tbleOption: {
        ...this.$store.getters.tableConfig,
        total: 20,
        align: 'center',
        cellBtn: false,
        addBtn: false,
        cancelBtn: false,
        filterBtn: false,
        selection: false,
        editBtn: false,
        delBtn: false,
        column: [
          {
            label: '所属平台',
            search: true,
            prop: 'platform'
          },
          {
            label: '执行操作',
            search: true,
            prop: 'action'
          },
          {
            label: '创建时间',
            addDisplay: false,
            editDisplay: false,
            prop: 'create_time'
          }
        ]
      },
      searchData: {}
    };
  },
  computed: {},
  mounted() {},
  filters: {},
  methods: {
    drawerInfoClose() {
      this.drawerInfoVisible = false;
    },
    // 分页
    onLoad(page) {
      this.page = page;
      this.getList();
    },
    viwClick(row) {
      dataApi.getone.r({ id: row.id }).then(res => {
        this.info = res.data;
        this.drawerInfoVisible = true;
      });
    },
    // 导出
    searchExcel(params) {
      // 处理日期
      const { datetime } = params;
      if (datetime) {
        // eslint-disable-next-line prefer-destructuring
        params.star_ttime = datetime[0];
        // eslint-disable-next-line prefer-destructuring
        params.end_time = datetime[1];
      }
      delete params.datetime;
      console.log(params);
      // 导出
      dataApi.getExport.r(params).then(res => {
        this.$confirm('是否下载导出数据?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          window.location.href = res.data;
        });
      });
    },
    // 筛选
    searchChange(params, done) {
      // 处理日期
      const { datetime } = params;
      if (datetime) {
        // eslint-disable-next-line prefer-destructuring
        params.star_ttime = datetime[0];
        // eslint-disable-next-line prefer-destructuring
        params.end_time = datetime[1];
      }
      delete params.datetime;
      this.searchData = params;
      this.page.currentPage = 1;
      this.getList();
      done();
    },
    // 筛选清空
    searchReset() {
      this.page.currentPage = 1;
      this.searchData = {};
      this.getList();
    },

    // 获取
    getList() {
      const postData = {
        page: this.page.currentPage,
        pageSize: this.page.pageSize,
        ...this.searchData
      };
      dataApi.getList.r(postData).then((res) => {
        console.log('接口返回', res);
        this.tableData = res.data.list;
        this.page.total = res.data.total;
      });
    }
  }
};
