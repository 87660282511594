<template>
  <div :class="{ 'page-class': !$store.getters.tableConfig.card }">
    <avue-crud
      :data="tableData"
      :option="tbleOption"
      @refresh-change="getList"
      @on-load="onLoad"
      @search-change="searchChange"
      @search-reset="searchReset"
      :page.sync="page"
    >
      <template slot-scope="{type,size,row}" slot="menu">
        <el-button icon="el-icon-view" :size="size" @click="viwClick(row)" :type="type">查看详情</el-button>
      </template>
    </avue-crud>
    <el-drawer
        title="交互详情"
        :before-close="drawerInfoClose"
        :visible.sync="drawerInfoVisible"
        size="80%">
      <div class="details">
        <div class="details-view">
          <div class="details-view-item">
            <div class="details-view-item-label">执行操作:</div>
            <div class="details-view-item-value">{{ info.action }}</div>
          </div>
          <div class="details-view-item">
            <div class="details-view-item-label">所属平台:</div>
            <div class="details-view-item-value">{{ info.platform }}</div>
          </div>
          <div class="details-view-item">
            <div class="details-view-item-label">创建时间:</div>
            <div class="details-view-item-value">{{ info.create_time }}</div>
          </div>
        </div>
      </div>
    </el-drawer>
  </div>
</template>
<script>
import List from './list';

export default List;
</script>
