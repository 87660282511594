import http from '@/utils/http';

/**
 * 平台交互日志
 */
export default {
  getList: {
    p: 'post,/system/platformaction/getlist',
    r: (data) => http({ url: '/system/platformaction/getlist', method: 'post', data })
  },
  add: {
    p: 'post,/system/platformaction/add',
    r: (data) => http({ url: '/system/platformaction/add', method: 'post', data })
  },
  del: {
    p: 'post,/system/platformaction/delete',
    r: (data) => http({ url: '/system/platformaction/delete', method: 'post', data })
  },
  getone: {
    p: 'post,/system/platformaction/getone',
    r: (data) => http({ url: '/system/platformaction/getone', method: 'post', data })
  },
  update: {
    p: 'post,/system/platformaction/update',
    r: (data) => http({ url: '/system/platformaction/update', method: 'post', data })
  }
};
