<template>
  <div :class="{ 'page-class': !$store.getters.tableConfig.card }">
    <avue-crud
      :data="tableData"
      :option="tbleOption"
      @row-update="rowUpdate"
      @refresh-change="getList"
      @row-del="rowDel"
      @on-load="onLoad"
      @search-change="searchChange"
      @search-reset="searchReset"
      :page.sync="page"
    >
      <template slot="searchMenu" slot-scope="{ row, size }">
        <el-button icon="el-icon-download" :size="size" @click="searchExcel(row)"
        >导出</el-button>
      </template>
      <template slot-scope="{type,size,row}" slot="menu">
        <el-button icon="el-icon-view" :size="size" @click="showDrawer(row)" :type="type">查看详情</el-button>
      </template>
      <template slot="member_nameSearch">
        <div style="height: 32px;width: 100%">
          <avue-form ref="form" :option="memberSearchOp" v-model="memberSearchFo" ></avue-form>
        </div>
      </template>
    </avue-crud>
    <el-drawer
      title="交易详情"
      :before-close="drawerInfoClose"
      :visible.sync="drawerInfoVisible"
      size="80%"
    >
      <div class="details">
        <!--交易详情-->
        <div class="details-header">
          <div class="details-title">基础信息</div>
        </div>
        <div class="details-view">
          <div class="details-view-item">
            <div class="details-view-item-label">订单号:</div>
            <div class="details-view-item-value">{{ drawerData.order_sn }}</div>
          </div>
          <div class="details-view-item">
            <div class="details-view-item-label">操作类型:</div>
            <div class="details-view-item-value">{{ drawerData.$pay_type}}</div>
          </div>
          <div class="details-view-item">
            <div class="details-view-item-label">会员名称:</div>
            <div class="details-view-item-value">{{ drawerData.member_name }}</div>
          </div>
          <div class="details-view-item" v-if="formDa.type === 'refund'">
            <div class="details-view-item-label">退款单号:</div>
            <div class="details-view-item-value">{{ drawerData.refund_id }}</div>
          </div>
          <div class="details-view-item" v-if="formDa.type === 'refund'">
            <div class="details-view-item-label">资金账号:</div>
            <div class="details-view-item-value">{{drawerData.funds_account }}</div>
          </div>
          <div class="details-view-item">
            <div class="details-view-item-label">交易金额:</div>
            <div class="details-view-item-value"
              >￥{{ drawerData.pay_amount }}元</div
            >
          </div>
          <div class="details-view-item">
            <div class="details-view-item-label">平台单号:</div>
            <div class="details-view-item-value"
              >{{ drawerData.transaction_id }}</div
            >
          </div>
          <div class="details-view-item">
            <div class="details-view-item-label">交易类型:</div>
            <div class="details-view-item-value">{{ drawerData.$type }}</div>
          </div>
          <div class="details-view-item">
            <div class="details-view-item-label">交易状态:</div>
            <div class="details-view-item-value">{{ drawerData.trade_state }}</div>
          </div>
          <div class="details-view-item">
            <div class="details-view-item-label">创建时间:</div>
            <div class="details-view-item-value">{{ drawerData.create_time }}</div>
          </div>
          <div class="details-view-item">
            <div class="details-view-item-label">完成时间:</div>
            <div class="details-view-item-value">{{ drawerData.success_time }}</div>
          </div>
        </div>
      </div>
    </el-drawer>
  </div>
</template>
<script>
import List from './list';

export default List;
</script>
