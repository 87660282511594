<template>
  <div :class="{ 'page-class': !$store.getters.tableConfig.card }">
    <avue-crud
      :data="tableData"
      :option="tbleOption"
      @refresh-change="getList"
      @on-load="onLoad"
      @search-change="searchChange"
      @search-reset="searchReset"
      :page.sync="page"
    >
      <template slot="menuLeft">
        <el-button
            icon="el-icon-upload"
            type="warning"
            size="small"
            @click.stop="syncData()"
        >数据同步</el-button>
      </template>
      <template slot-scope="{ type, size, row }" slot="menu">
        <el-button
            icon="el-icon-upload"
            :size="size"
            :type="type"
            @click.stop="rowsSync(row)"
        >数据同步</el-button
        >
      </template>
    </avue-crud>
    <el-drawer
        title="充值详情"
        :before-close="drawerInfoClose"
        :visible.sync="drawerInfoVisible"
        size="80%">
      <div class="details">
        <div class="details-view">
          <div class="details-view-item">
            <div class="details-view-item-label">流水号:</div>
            <div class="details-view-item-value">{{ info.open_id }}</div>
          </div>
          <div class="details-view-item">
            <div class="details-view-item-label">能量值:</div>
            <div class="details-view-item-value">{{ info.energy }}个</div>
          </div>
          <div class="details-view-item">
            <div class="details-view-item-label">支付类型:</div>
            <div class="details-view-item-value">{{ info.pay_type_text }}</div>
          </div>
          <div class="details-view-item">
            <div class="details-view-item-label">支付金额:</div>
            <div class="details-view-item-value">{{ info.pay_amount }}元</div>
          </div>
          <div class="details-view-item">
            <div class="details-view-item-label">open_id:</div>
            <div class="details-view-item-value">{{ info.open_id }}</div>
          </div>
          <div class="details-view-item">
            <div class="details-view-item-label">使用能量:</div>
            <div class="details-view-item-value">{{ info.use_energy }}个</div>
          </div>
          <div class="details-view-item">
            <div class="details-view-item-label">兑换比:</div>
            <div class="details-view-item-value">{{ info.prop }}</div>
          </div>
          <div class="details-view-item">
            <div class="details-view-item-label">状态:</div>
            <div class="details-view-item-value">{{ info.status_text }}</div>
          </div>
          <div class="details-view-item">
            <div class="details-view-item-label">创建时间:</div>
            <div class="details-view-item-value">{{ info.create_time }}</div>
          </div>
        </div>
      </div>
    </el-drawer>
  </div>
</template>
<script>
import List from './list';

export default List;
</script>
