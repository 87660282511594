import http from '@/utils/http';

/**
 * 微盟会员
 */
export default {
  getList: {
    p: 'post,/system/weimobaccount/getlist',
    r: (data) => http({ url: '/system/weimobaccount/getlist', method: 'post', data })
  },
  sync_data: {
    p: 'post,/system/weimobaccount/sync_data',
    r: (data) => http({ url: '/system/weimobaccount/sync_data', method: 'post', data })
  },
  getone: {
    p: 'post,/system/weimobaccount/getone',
    r: (data) => http({ url: '/system/weimobaccount/getone', method: 'post', data })
  }
};
