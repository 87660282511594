import './list.scss';
import dataApi from '@/api/member/xiaobaoaccount.js';
/*
*校宝会员
* */
export default {
  name: 'member-points',
  components: {},
  data() {
    return {
      drawerInfoVisible: false,
      info: {},
      page: {
        pageSize: 20,
        pageSizes: [20, 30, 40, 50, 100]
      },
      tableData: [],
      tbleOption: {
        ...this.$store.getters.tableConfig,
        total: 20,
        align: 'center',
        cellBtn: false,
        editBtn: false,
        delBtn: false,
        addBtn: false,
        cancelBtn: false,
        filterBtn: false,
        selection: false,
        column: [
          {
            label: 'stuid',
            prop: 'stuid'
          },
          {
            label: '学员姓名',
            search: true,
            prop: 'name'
          },
          {
            label: '手机号',
            search: true,
            prop: 'phone'
          },
          {
            label: '金额',
            prop: 'money'
          },
          {
            label: '剩余课时',
            prop: 'class_times'
          },
          {
            type: 'select',
            label: '关联会员',
            search: true,
            span: 24,
            display: true,
            props: {
              label: 'label',
              value: 'value'
            },
            dicUrl: `${this.$baseUrl}general/get_member?keyword={{key}}`,
            remote: true,
            dicMethod: 'post',
            prop: 'member_id',
            required: true,
            rules: [
              {
                required: true,
                message: '会员必须填写'
              }
            ]
          },
          {
            type: 'radio',
            label: '状态',
            display: true,
            prop: 'status',
            dicData: [
              {
                label: '在读',
                value: 1
              },
              {
                label: '转出',
                value: 2
              },
              {
                label: '退费',
                value: 3
              },
              {
                label: '停课',
                value: 4
              },
              {
                label: '结课',
                value: 5
              },
              {
                label: '复课',
                value: 6
              },
              {
                label: '停课',
                value: 7
              },
              {
                label: '无',
                value: -1
              }
            ]
          },
          {
            label: '创建时间',
            addDisplay: false,
            editDisplay: false,
            prop: 'create_time'
          }
        ]
      },
      searchData: {}
    };
  },
  computed: {},
  mounted() {},
  filters: {},
  methods: {
    rowsSync(row) {
      this.$confirm('是否同步该行数据?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        dataApi.sync_data.r({ stuid: row.stuid }).then(res => {
          this.$message({
            message: '同步成功',
            type: 'success'
          });
          console.log(res);
        });
      }).catch(() => {
        this.$message({
          message: '已取消',
          type: 'info'
        });
      });
    },
    // 数据同步
    syncData() {
      this.$confirm('是否同步数据?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        dataApi.sync_data.r().then(res => {
          this.$message({
            message: '同步成功',
            type: 'success'
          });
          console.log(res);
        });
      }).catch(() => {
        this.$message({
          message: '已取消',
          type: 'info'
        });
      });
    },
    drawerInfoClose() {
      this.drawerInfoVisible = false;
    },
    // 分页
    onLoad(page) {
      this.page = page;
      this.getList();
    },
    viwClick(row) {
      dataApi.getone.r({ id: row.id }).then(res => {
        console.log(res);
        this.info = res.data;
        this.drawerInfoVisible = true;
      });
    },
    // 导出
    searchExcel(params) {
      // 处理日期
      const { datetime } = params;
      if (datetime) {
        // eslint-disable-next-line prefer-destructuring
        params.star_ttime = datetime[0];
        // eslint-disable-next-line prefer-destructuring
        params.end_time = datetime[1];
      }
      delete params.datetime;
      console.log(params);
      // 导出
      dataApi.getExport.r(params).then(res => {
        this.$confirm('是否下载导出数据?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          window.location.href = res.data;
        });
      });
    },
    // 筛选
    searchChange(params, done) {
      // 处理日期
      const { datetime } = params;
      if (datetime) {
        // eslint-disable-next-line prefer-destructuring
        params.star_ttime = datetime[0];
        // eslint-disable-next-line prefer-destructuring
        params.end_time = datetime[1];
      }
      delete params.datetime;
      this.searchData = params;
      this.page.currentPage = 1;
      this.getList();
      done();
    },
    // 筛选清空
    searchReset() {
      this.page.currentPage = 1;
      this.searchData = {};
      this.getList();
    },

    // 获取
    getList() {
      const postData = {
        page: this.page.currentPage,
        pageSize: this.page.pageSize,
        ...this.searchData
      };
      dataApi.getList.r(postData).then((res) => {
        console.log('接口返回', res);
        this.tableData = res.data.list;
        this.page.total = res.data.total;
      });
    }
  }
};
