import './list.scss';
import dataApi from '@/api/manage/payment.js';
import member from '@/api/member/member';
/*
* 支付日志
*  */
export default {
  name: 'manage-payment',
  components: {},
  data() {
    return {
      page: {
        pageSize: 20,
        pageSizes: [20, 30, 40, 50, 100]
      },
      drawerInfoVisible: false,
      tableData: [],
      tbleOption: {
        total: 20,
        align: 'center',
        cellBtn: false,
        cancelBtn: false,
        addBtn: false,
        viewBtn: false,
        editBtn: false,
        delBtn: false,
        ...this.$store.getters.tableConfig,
        selection: false,
        column: [
          {
            label: '订单号',
            prop: 'order_sn',
            type: 'input',
            search: 'true'
          },
          {
            label: '交易金额',
            prop: 'pay_amount'
          },
          {
            type: 'radio',
            label: '类型',
            value: '',
            dicData: [
              {
                label: '收款',
                value: 'pay'
              },
              {
                label: '退款',
                value: 'refund'
              }
            ],
            display: true,
            search: 'true',
            prop: 'type'
          },
          {
            type: 'radio',
            label: '支付渠道',
            value: '',
            dicData: [
              {
                label: '线下收款',
                value: 'cash'
              },
              {
                label: '微信支付',
                value: 'wx_miniapp'
              },
              {
                label: '微信支付',
                value: 'wx_qrcode'
              }
            ],
            display: true,
            search: 'true',
            prop: 'pay_type'
          },
          {
            type: 'radio',
            label: '交易状态',
            value: 1,
            dicData: [
              {
                label: '交易成功',
                value: 'SUCCESS'
              },
              {
                label: '转入退款',
                value: 'REFUND'
              },
              {
                label: '交易关闭',
                value: 'CLOSED'
              },
              {
                label: '交易撤销',
                value: 'REVOKED'
              },
              { label: '等待支付', value: 'NOTPAY' },
              { label: '用户支付中', value: 'USERPAYING' },
              { label: '支付失败', value: 'PAYERROR' },
              { label: '退款处理中', value: 'PROCESSING' },
              { label: '退款异常', value: 'ABNORMAL' }
            ],
            display: true,
            search: 'true',
            prop: 'trade_state'
          },
          // {
          //   label: '平台单号',
          //   prop: 'transaction_id'
          // },
          {
            label: '会员名称',
            addDisplay: false,
            editDisplay: false,
            prop: 'member_name',
            search: true
          },
          {
            label: '备注',
            prop: 'trade_product'
          },
          {
            label: '创建时间',
            prop: 'create_time'
          },
          {
            // 更新时间 update_time
            label: '完成时间',
            prop: 'success_time'
          },
          {
            type: 'radio',
            label: '日期类型',
            value: '',
            dicData: [
              {
                label: '创建时间',
                value: 'create'
              },
              {
                label: '完成时间',
                value: 'update'
              }
            ],
            hide: true,
            display: false,
            search: 'true',
            prop: 'date_type',
            span: 6
          },
          {
            type: 'datetime',
            searchRange: true,
            label: '日期范围',
            span: 8,
            hide: true,
            display: false,
            search: 'true',
            format: 'yyyy-MM-dd',
            valueFormat: 'yyyy-MM-dd',
            prop: 'datetime'
          }
        ]
      },
      searchData: {},
      formDa: {},
      drawerData: {},
      memberSearchOp: {
        span: 24,
        submitBtn: false,
        emptyBtn: false,
        column: [
          {
            labelWidth: 0,
            label: '',
            prop: 'table',
            type: 'table',
            children: {
              border: true,
              searchMenuSpan: 8,
              column: [
                {
                  type: 'input',
                  label: '会员名称',
                  display: true,
                  prop: 'name',
                  searchLabelWidth: 100,
                  search: true
                },
                {
                  type: 'input',
                  label: '手机号',
                  display: true,
                  prop: 'mobile',
                  searchLabelWidth: 100,
                  search: true
                },
                {
                  label: '能量值',
                  prop: 'energy'
                },
                {
                  label: '赠送能量值',
                  prop: 'giving_energy'
                }
              ]
            },
            formatter: (row) => {
              if (!row.name) return '';
              return `${row.name}`;
            },
            onLoad: ({ page, value, data }, callback) => {
              // 首次加载去查询对应的值
              if (value) {
                return;
              }
              // 请求列表数据
              member.getList.r({ ...data, page: page.currentPage, pageSize: page.pageSize }).then((res) => {
                // 渲染数据
                callback({
                  total: res.data.total,
                  data: [...res.data.list]
                });
              });
            },
            props: {
              label: 'name',
              value: 'id'
            }
          }
        ]
      },
      memberSearchFo: {}
    };
  },
  computed: {},
  mounted() {},
  filters: {},
  methods: {
    showDrawer(row) {
      this.drawerData = { ...row };
      console.log(this.drawerData);
      this.drawerInfoVisible = true;
    },
    // 分页
    onLoad(page) {
      this.page = page;
      this.getList();
    },
    // 筛选
    searchChange(params, done) {
      if (this.memberSearchFo.table) {
        params.member_id = this.memberSearchFo.table;
      }
      // 处理日期
      const { datetime } = params;
      if (datetime) {
        // eslint-disable-next-line prefer-destructuring
        params.start_time = datetime[0];
        // eslint-disable-next-line prefer-destructuring
        params.end_time = datetime[1];
      }
      delete params.datetime;
      this.searchData = params;
      this.page.currentPage = 1;
      this.getList();
      done();
    },
    // 筛选清空
    searchReset() {
      this.page.currentPage = 1;
      this.searchData = {};
      this.getList();
    },
    // 导出
    searchExcel(params) {
      if (this.memberSearchFo.table) {
        params.member_id = this.memberSearchFo.table;
      }
      // 处理日期
      const { datetime } = params;
      if (datetime) {
        // eslint-disable-next-line prefer-destructuring
        params.start_time = datetime[0];
        // eslint-disable-next-line prefer-destructuring
        params.end_time = datetime[1];
      }
      delete params.datetime;
      dataApi.exportFile.r(params).then((res) => {
        this.$confirm('是否下载导出数据?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
          center: false
        }).then(() => {
          window.location.href = res.data;
        });
      });
    },
    rowView(row) {
      this.getInfo(row);
      // 加载佣金
      // this.getActionLog(row);
      this.drawerInfoVisible = true;
    },
    drawerInfoClose() {
      this.drawerInfoVisible = false;
    },
    // 删除
    rowDel(form) {
      this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        console.log('删除数据', form);
        dataApi.del.r({ id: form.id }).then((res) => {
          console.log(res);
          this.getList();
          this.$message.success('删除成功');
        });
      });
    },
    // 修改
    rowUpdate(form, index, done, loading) {
      console.log('index', form, index);
      dataApi.update.r(form).then((res) => {
        console.log(res);
        done(form);
        this.getList();
        loading();
      });
      done();
    },
    // 获取
    getList() {
      const postData = {
        page: this.page.currentPage,
        pageSize: this.page.pageSize,
        ...this.searchData
      };
      dataApi.getList.r(postData).then((res) => {
        console.log('接口返回', res);
        this.tableData = res.data.list;
        this.page.total = res.data.total;
      });
    }
  }
};
