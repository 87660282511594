import http from '@/utils/http';

/**
 * 校宝会员
 */
export default {
  getList: {
    p: 'post,/system/xiaobaoaccount/getlist',
    r: (data) => http({ url: '/system/xiaobaoaccount/getlist', method: 'post', data })
  },
  sync_data: {
    p: 'post,/system/xiaobaoaccount/sync_data',
    r: (data) => http({ url: '/system/xiaobaoaccount/sync_data', method: 'post', data })
  },
  getone: {
    p: 'post,/system/xiaobaoaccount/getone',
    r: (data) => http({ url: '/system/xiaobaoaccount/getone', method: 'post', data })
  }
};
