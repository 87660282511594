import http from '@/utils/http';

/**
 * 队列日志
 */
export default {
  getList: {
    p: 'post,/system/queue/getlist',
    r: (data) => http({ url: '/system/queue/getlist', method: 'post', data })
  },
  add: {
    p: 'post,/system/queue/add',
    r: (data) => http({ url: '/system/queue/add', method: 'post', data })
  },
  del: {
    p: 'post,/system/queue/delete',
    r: (data) => http({ url: '/system/queue/delete', method: 'post', data })
  },
  getone: {
    p: 'post,/system/queue/getone',
    r: (data) => http({ url: '/system/queue/getone', method: 'post', data })
  },
  update: {
    p: 'post,/system/queue/update',
    r: (data) => http({ url: '/system/queue/update', method: 'post', data })
  }
};
